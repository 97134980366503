@charset "UTF-8";

/* =============================================================================
   Top Nav Styles
   ========================================================================== */

nav.header {
  width: 100%;
  background: none;
  background-color: transparent;
  box-shadow: 0px 0px transparent;
  color: white;
  font-family: "Quicksand", sans-serif;
}

nav .logo img {
  padding-top: 25px;
  width: 150px;
}

input {
  background: #fff;
  padding: 10px 15px;
  border: 0.5px #d8d8d8 solid;
  font-size: 13px;
  font-weight: 600;
  border-radius: none;
}

input:not([type="password"], [name="password"], [name="passwordConfirm"]) {
  text-transform: uppercase;
}

/* =============================================================================
   Text styles
   ========================================================================== */

h1 {
  font-family: "source-sans-pro", sans-serif;
  font-size: 56px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 60px;
}

h2 {
  font-family: "source-sans-pro", sans-serif;
  font-size: 32px;
  color: #2d2d2d;
  letter-spacing: 0;
  line-height: 42px;
}

h3 {
  font-family: "source-sans-pro", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #2d2d2d;
  letter-spacing: 0.86px;
}

p {
  font-family: "source-sans-pro", sans-serif;
  font-size: 16px;
  color: #2d2d2d;
  letter-spacing: 0.86px;
}

.card p {
  font-family: "source-sans-pro", sans-serif;
  font-size: 14px;
  color: #9b9b9b;
  letter-spacing: 0.75px;
  line-height: 18px;
  width: 80%;
  margin-left: 10%;
}

.bg_hero p {
  color: #fff;
  font-weight: 500;
}

.cta_bg h3 {
  color: #fff;
  font-size: 24px;
}

.cta_bg .link {
  color: #3d8eea;
}

.link {
  color: #ebb679;
  text-decoration: none;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.errors,
.errors error {
  color: #b94a48;
}

.message-area * {
  text-align: center;
  display: block;
}

.return-info {
  color: #5cb85c;
}

/* =============================================================================
   Page Layout styles
   ========================================================================== */

body {
  margin: 0px;
  overflow-x: hidden;
}

.smooth {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.fast {
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.right {
  float: right;
}

.left {
  float: left;
}

.text_right {
  text-align: left;
}

.text_center {
  text-align: center;
}

.text_left {
  text-align: left;
}

.margin {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.padding {
  padding: 20px 0px !important;
}

img.responsive-img {
  width: 100%;
}

.btn {
  border-radius: 4px;
  border: 0px transparent;
  color: white;
  font-weight: 100;
  font-size: 14px;
  padding: 7px 15px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  min-height: 34px;
  position: relative;
  background-color: #ecb679;
}

.btn_ghost {
  padding: 10px 35px;
  border: 2px solid #fff;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  background: none;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
}

.btn_ghost:hover {
  background: #fff;
  color: #ebb679;
  cursor: pointer;
}

/* =============================================================================
   Form Styles
   ========================================================================== */
#forgot-password h3 h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: "source-sans-pro", sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: #4d4d4d;
}

#forgot-password h3 {
  font-size: 24px;
}

#forgot-password button[type="submit"] {
  float: right;
}

.forgot-password-form fieldset {
  border: 1px solid #e5e5e5;
}

#forgot-password::selection {
  background: #fe9524;
  color: white;
  text-shadow: none;
}

/* Password toggle field and it's label
 */
form input#showPasswordField,
form label[for="#showPasswordField"] {
  width: unset;
  cursor: pointer;
}

fieldset {
  border: none;
}

/* =============================================================================
   Layout Element Styles
   ========================================================================== */

.bg_hero {
  background-image: linear-gradient(90deg, #3a97d3 46%, #3d8eea 80%, #3f89f8 89%, #4087fd 97%);
  width: 100%;
  height: 700px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.hero_image {
  width: 140%;
  margin-left: -150px;
  margin-top: -120px;
}

.gray_bg {
  background: #f5f5f5;
  padding: 40px 0px;
}

.cta_bg {
  background-image: linear-gradient(-220deg, #ebb679 0%, #d78f47 100%);
  text-align: center;
}

.section_2 {
  padding-bottom: 100px !important;
}

.login-form {
  display: none;
  padding: 25px 0px;
  float: right;
  width: 100%;
}

.header .login-form .errors {
  color: #960000;
}

.header .user-name {
  display: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.86px;
  color: white;
  padding: 20px;
}

#open_menu {
  float: right;
  margin: 18px 20px 0px 0px;
}

.header_form {
  opacity: 0;
  background: #fff;
  padding: 20px 0px 0px 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  color: #2d2d2d;
  position: fixed;
  margin: -350px 0px 0px 0px;
  left: 0px !important;
  top: 0px;
  float: left !important;
  text-align: center;
}

.header_form p {
  color: #0fa2e2;
  margin-top: 10px;
}

.header_form input {
  width: 50%;
  margin-left: 20%;
  padding: 10px 5%;
}

.header_form .btn {
  width: 50%;
  margin-left: 0%;
  padding: 10px 0px;
}

.header_form .close.show {
  position: fixed;
  top: 10px;
  right: 10px;
}

.close:hover {
  cursor: pointer;
}

.header_form.show {
  opacity: 1;
  margin: 0px 0px 0px 0px;
}

.card {
  margin-top: -50px;
  padding: 20px 0px;
  background: #ffffff;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
}

.card .l4 {
  padding: 10px 0px;
}

/* =============================================================================
   Responsive Grid Section Layouts
   ========================================================================== */

.row {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: inline-block;
}

.col {
  margin-left: 0px;
  left: auto;
  right: auto;
  display: inline-block;
  clear: none;
  float: left;
}

.hide_mobile {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.hide_desktop {
  display: inline-block;
  opacity: 1;
  visibility: visible;
}

/* =============================================================================
   Mobile Layouts
   ========================================================================== */

.s1 {
  width: 8.333333%;
}
.s2 {
  width: 16.666667%;
}
.s3 {
  width: 25%;
}
.s4 {
  width: 33.3333333333%;
}
.s5 {
  width: 41.6666666667%;
}
.s6 {
  width: 50%;
}
.s7 {
  width: 58.33333333%;
}
.s8 {
  width: 66.6666667%;
}
.s9 {
  width: 75%;
}
.s10 {
  width: 83.3333333333%;
}
.s11 {
  width: 91.6666666667%;
}
.s12 {
  width: 100%;
}
.s13 {
  width: 110%;
  margin-left: -5%;
}

.offset-s1 {
  margin-left: 8.333333%;
}
.offset-s2 {
  margin-left: 16.666667%;
}
.offset-s3 {
  margin-left: 25%;
}
.offset-s4 {
  margin-left: 33.3333333333%;
}
.offset-s5 {
  margin-left: 41.6666666667%;
}
.offset-s6 {
  margin-left: 50%;
}
.offset-s7 {
  margin-left: 58.33333333%;
}
.offset-s8 {
  margin-left: 66.6666667%;
}
.offset-s9 {
  margin-left: 75%;
}
.offset-s10 {
  margin-left: 83.3333333333%;
}
.offset-s11 {
  margin-left: 91.6666666667%;
}
.offset-s12 {
  margin-left: 100%;
}

/* =============================================================================
   Table Layouts
   ========================================================================== */

@media only screen and (min-width: 601px) {
  .hero_image {
    width: 140%;
    margin-left: -0px;
    margin-top: 100px;
  }

  .m1 {
    width: 8.333333%;
  }
  .m2 {
    width: 16.666667%;
  }
  .m3 {
    width: 25%;
  }
  .m4 {
    width: 33.3333333333%;
  }
  .m5 {
    width: 41.6666666667%;
  }
  .m6 {
    width: 50%;
  }
  .m7 {
    width: 58.33333333%;
  }
  .m8 {
    width: 66.6666667%;
  }
  .m9 {
    width: 75%;
  }
  .m10 {
    width: 83.3333333333%;
  }
  .m11 {
    width: 91.6666666667%;
  }
  .m12 {
    width: 100%;
  }

  .offset-m0 {
    margin-left: 0px;
  }
  .offset-m1 {
    margin-left: 8.333333%;
  }
  .offset-m2 {
    margin-left: 16.666667%;
  }
  .offset-m3 {
    margin-left: 25%;
  }
  .offset-m4 {
    margin-left: 33.3333333333%;
  }
  .offset-m5 {
    margin-left: 41.6666666667%;
  }
  .offset-m6 {
    margin-left: 50%;
  }
  .offset-m7 {
    margin-left: 58.33333333%;
  }
  .offset-m8 {
    margin-left: 66.6666667%;
  }
  .offset-m9 {
    margin-left: 75%;
  }
  .offset-m10 {
    margin-left: 83.3333333333%;
  }
  .offset-m11 {
    margin-left: 91.6666666667%;
  }
  .offset-m12 {
    margin-left: 100%;
  }
}

/* =============================================================================
   Desktop Layouts
   ========================================================================== */

@media only screen and (min-width: 993px) {
  .hide_mobile {
    display: inline-block;
    opacity: 1;
    visibility: visible;
  }

  .hide_desktop {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  h1 {
    font-family: "Poppins", sans-serif;
    font-size: 56px;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 56px;
  }

  nav .logo img {
    padding-top: 25px;
    width: 250px;
  }

  .hero_image {
    width: 150%;
    margin-left: 5%;
    margin-top: -100px;
  }

  .cta_bg {
    text-align: left;
  }

  .text_right {
    text-align: right;
  }

  .margin {
    margin-top: 150px;
    margin-bottom: 100px;
  }

  .padding {
    padding: 50px 0px !important;
  }

  .section_2 {
    padding-bottom: 175px !important;
  }

  .card {
    margin-top: -150px;
  }

  .header_form {
    opacity: 1;
    background: none;
    padding: 0px;
    height: 50px;
    box-shadow: 0 0 00px 0 rgba(0, 0, 0, 0);
    color: #2d2d2d;
    position: static;
    margin: 0px;
    top: 0px;
    float: left !important;
  }

  .header_form input {
    width: 26%;
    margin-left: 0%;
    padding: 12px 20px;
  }

  .header_form .btn {
    width: 25%;
    margin-left: -55px;
    padding: 9px 20px;
  }

  .l1 {
    width: 8.333333%;
  }
  .l2 {
    width: 16.666667%;
  }
  .l3 {
    width: 25%;
  }
  .l4 {
    width: 33.3333333333%;
  }
  .l5 {
    width: 41.6666666667%;
  }
  .l6 {
    width: 50%;
  }
  .l7 {
    width: 58.33333333%;
  }
  .l8 {
    width: 66.6666667%;
  }
  .l9 {
    width: 75%;
  }
  .l10 {
    width: 83.3333333333%;
  }
  .l11 {
    width: 91.6666666667%;
  }
  .l12 {
    width: 100%;
  }

  .offset-l0 {
    margin-left: 0px;
  }
  .offset-l1 {
    margin-left: 8.333333%;
  }
  .offset-l2 {
    margin-left: 16.666667%;
  }
  .offset-l3 {
    margin-left: 25%;
  }
  .offset-l4 {
    margin-left: 33.3333333333%;
  }
  .offset-l5 {
    margin-left: 41.6666666667%;
  }
  .offset-l6 {
    margin-left: 50%;
  }
  .offset-l7 {
    margin-left: 58.33333333%;
  }
  .offset-l8 {
    margin-left: 66.6666667%;
  }
  .offset-l9 {
    margin-left: 75%;
  }
  .offset-l10 {
    margin-left: 83.3333333333%;
  }
  .offset-l11 {
    margin-left: 91.6666666667%;
  }
  .offset-l12 {
    margin-left: 100%;
  }
}
@media only screen and (min-width: 1440px) {
  .hero_image {
    width: 120%;
    margin-left: 5%;
    margin-top: -100px;
  }
}
/* =============================================================================
   Lightbox
   ========================================================================== */

.popus {
  position: absolute;
  top: -9999px;
  left: -99999px;
}

.lightbox {
  width: 600px;
  height: auto;
  /*
    border:3px solid #aeaeae;
    border-radius:2px;
    */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  font: 18px/27px "source-sans-pro", sans-serif;
  font-weight: 300;
  padding: 19px 20px 20px;
  color: #666;
  position: relative;
}

.lightbox h3 {
  font: 20px/39px "proxima-nova", sans-serif;
  font-weight: bold;
  color: #aeaeae;
  margin: 0 0 23px;
  text-transform: uppercase;
  text-align: center;
}

.lightbox .row {
  overflow: hidden;
  margin: 0 0 15px;
}

.lightbox label {
  float: left;
  width: 100%;
  line-height: 1.42857143;
}

.lightbox .text {
  width: 548px;
  height: 20px;
  float: left;
  color: #666;
  font: 15px/20px "source-sans-pro", sans-serif;
  font-weight: 300;
  padding: 6px;
  background: #fff;
}

.lightbox .submit {
  display: block;
  margin: 33px auto 0;
  width: 127px;
  height: 39px;
  background: #fe9524;
  color: #fff;
  font: 14px/39px "proxima-nova", sans-serif;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  border: 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}

/* =============================================================================
   Print Styles
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }

  /* Black prints faster: h5bp.com/s */
  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  /* Don't show links for images, or javascript/internal links */
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  /* h5bp.com/t */
  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

/* =============================================================================
   Fancybox Styles - fancyBox v2.1.3 fancyapps.com
   ========================================================================== */

.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  background: #f7f8fa;
  color: #4d4d4d;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-opened .fancybox-skin {
}

.fancybox-outer,
.fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #b94a48;
  font: 18px/24px "source-sans-pro", sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url("../../assets/public/img/fancybox_sprite.png");
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("../../assets/public/img/fancybox_loading.gif") center center no-repeat;
}

.fancybox-close {
  position: absolute;
  top: 5px;
  right: 3px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("../../assets/public/img/blank.gif"); /* helps IE */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
  overflow: hidden;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("../../assets/public/img/fancybox_overlay.png");
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "source-sans-pro", sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent; /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #fff;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}
